<!--  -->
<template>
  <div class="main_box">
    <div class="nav_menu">
      <div class="menu_left">
        <el-input
          placeholder="请输入站点名称"
          v-model="dayInfo.value"
        ></el-input>
        <el-date-picker
          :editable="false"
          v-model="dayInfo.time"
          type="date"
          placeholder="选择日期"
          class="check_date"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <el-button type="primary" @click="handleSearch(0)">查询</el-button>
        <el-button type="info" @click="handleSearch(1)">重置</el-button>
      </div>
      <div class="menu_right">
        <el-button type="success" @click="handleExport">导出</el-button>
      </div>
    </div>
    <div>
      <el-table
        :header-cell-style="{
          'background-color': '#4073b6',
          color: '#FFFFFF'
        }"
        fit
        :data="tableData"
        stripe
        style="width: 100%"
        v-loading="loading"
        element-loading-text="数据量过大，加载中..."
      >
        <el-table-column type="index" prop="sid" label="序号" width="55">
        </el-table-column>
        <el-table-column
          type="index"
          prop="stationName"
          label="站点名称"
          width="76"
          :show-overflow-tooltip="true"
        >
          <template scope="scope">
            {{ scope.row.stationName }}
          </template>
        </el-table-column>
        <el-table-column
          type="index"
          prop="date"
          label="降雨日期"
          width="76"
          :show-overflow-tooltip="true"
        >
          <template scope="scope">
            {{ scope.row.date }}
          </template>
        </el-table-column>
        <el-table-column
          v-for="col in cols"
          :prop="col.prop"
          :label="col.label"
          :key="col.prop"
          width="53"
        >
        </el-table-column>
        <el-table-column label="总降雨量">
          <template scope="scope">
            {{ scope.row.sumRainfall }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      :hide-on-single-page="true"
      background
      layout="total, prev, pager, next, jumper"
      :total="pagination.total"
      @current-change="handleCurrent"
      :current-page="pagination.page"
    >
    </el-pagination>
  </div>
</template>

<script>
import { rainDay, rainExport, rainFall } from "../../../api/index";
import { dayList } from "../index";
import axios from "axios";
export default {
  components: {},
  data() {
    return {
       pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      tableData: [],
      value: "",
      pagination: {
        // 分页
        size: 10,
        page: 1,
        total: 0
      },
      cols: dayList,
      time: "",
      dayInfo: {
        time: "",
        value: ""
      },
      loading: false
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 月份小于10自动加0
    nums(s) {
      return s < 10 ? "0" + s : s;
    },
    getList() {
      this.loading = true;
      let { page, size } = this.pagination;
      let date = new Date();
      let { value, time } = this.dayInfo;
      this.time =
        date.getFullYear() +
        "-" +
        this.nums(date.getMonth() + 1) +
        "-" +
        date.getDate();
      rainDay({
        page,
        size,
        rainName: value,
        time: this.time
      }).then(res => {
        this.loading = false;
        this.tableData = res.data.list.map(item => {
          let obj = {};
          obj.stationName = item.rainName;
          item["dayRains"].forEach(items => {
            obj["rainHour" + items.rainHour] = items["rainfall"];
          });
          obj["sumRainfall"] = item.sumRainfall;
          obj["date"] = this.time;
          return obj;
        });
        this.pagination.total = res.data.total;
      });
    },
    // 分页切换
    handleCurrent(pages) {
      this.loading = true;
      this.pagination.page = pages;
      let { page, size } = this.pagination;
      let { value, time } = this.dayInfo;
      rainDay({
        page,
        size,
        rainName: value,
        time: this.time
      }).then(res => {
        this.loading = false;
        this.tableData = res.data.list.map(item => {
          let obj = {};
          obj.stationName = item.rainName;
          item["dayRains"].forEach(items => {
            obj["rainHour" + items.rainHour] = items["rainfall"];
          });
          obj["sumRainfall"] = item.sumRainfall;
          obj["date"] = this.time;
          return obj;
        });
        this.pagination.total = res.data.total;
      });
    },
    // 查询
    handleSearch(type) {
      if (type == 1) {
        this.dayInfo = {};
        this.pagination.page = 1;
        this.getList();
      } else {
        let date = new Date();
        let { value, time } = this.dayInfo;
        if (time == "") {
          time =
            date.getFullYear() +
            "-" +
            this.nums(date.getMonth() + 1) +
            "-" +
            date.getDate();
        } else {
          this.time = time;
        }
        rainDay({
          page: 1,
          size: 10,
          rainName: value,
          time
        }).then(res => {
          this.tableData = res.data.list.map(item => {
            let obj = {};
            obj.stationName = item.rainName;
            item["dayRains"].forEach(items => {
              obj["rainHour" + items.rainHour] = items["rainfall"];
            });
            obj["sumRainfall"] = item.sumRainfall;
            obj["date"] = this.time;
            return obj;
          });
        });
      }
    },
    // 导出
    handleExport() {
      this.$axios({
        method: "get",
        url: "/rainfallRegime/exportRainDay",
        params: {
          rainName: "",
          time: this.time
        },
        responseType: "blob"
      })
        .then(res => {
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;charset=utf-8"
          }); // 创建一个类文件对象：Blob对象表示一个不可变的、原始数据的类文件对象
          const elink = document.createElement("a"); // 创建一个a标签
          elink.download = "日报表" + this.time; // 设置a标签的下载属性
          elink.style.display = "none"; // 将a标签设置为隐藏
          elink.href = URL.createObjectURL(blob); // 把之前处理好的地址赋给a标签的href
          document.body.appendChild(elink); // 将a标签添加到body中
          elink.click(); // 执行a标签的点击方法
          URL.revokeObjectURL(elink.href); // 下载完成释放URL 对象
          document.body.removeChild(elink); // 移除a标签
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created() {
    this.getList();
  },
  mounted() {}
};
</script>
<style lang='scss' scoped>
::v-deep .el-table {
  height: calc(780 / 1080 * 100vh) !important;
  margin-bottom: 15px!important;
  background: transparent;
}
</style>
